import React from 'react'
import { Link } from 'gatsby'

export default function FooterBanner() {
  return (
    <section className='section--full-width footer-banner'>
      <div className='footer-banner__wrapper'>
        <h4 className='footer-banner__title h4--white'>
          Let’s build the next big thing together.
        </h4>
        <div className='footer-banner__button'>
          <Link to='/contact-us' className='primary-button primary-button--white'>
            contact us
            <div className='primary-button__icon'>
              <div className='icon-right-arrow-icon'></div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}
