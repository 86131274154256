import React, { Component } from 'react'
import { Link } from 'gatsby'
import { menuContext } from '../utils/context-providers'

export default class Header extends Component {
  toggleElementClasses() {
    this.logo.classList.toggle('hidden')
    this.logoAlt.classList.toggle('visible')
    this.overlay.classList.toggle('show-menu-select-overlay')
    document.querySelector('html').classList.toggle('no-scroll')
  }

  toggleMenu(event) {
    event.preventDefault()
    const mainNavigationWrapper = document.querySelector('.main-navigation-wrapper')

    if (this.context.open) {
      mainNavigationWrapper.classList.toggle('visible')

      setTimeout(() => {
        this.toggleElementClasses()
      }, 350)
    } else {
      this.toggleElementClasses()

      setTimeout(() => {
        mainNavigationWrapper.classList.toggle('visible')
      }, 350)
    }

    this.context.toggleOpen()
  }

  static contextType = menuContext

  render() {
    return (
      <header>
        <nav className='nav-container'>
          <div className='logo__container'>
            <Link className='logo' to='/' ref={el => this.logo = el}></Link>
            <Link className='logo--wbg' to='/'></Link>
            <Link className='logo--alt' to='/'ref={el => this.logoAlt = el}></Link>
          </div>
          <div className={`menu-title ${this.context.open ? 'white-title' : ''}`}>
            {this.context.open ? 'close menu' : 'menu'}
          </div>
          <div className='menu-select__wrapper'>
            <div className='menu-select'>
              <div className='menu-select__icon'>
                {/* eslint-disable-next-line */}
                <a className={`button-nav ${this.context.open ? 'toggled' : ''}`}
                  onClick={(e) => this.toggleMenu(e)}
                >
                  <span className='menu-bar bar1'></span>
                  <span className='menu-bar bar2'></span>
                  <span className='menu-bar bar3'></span>
                </a>
              </div>
            </div>

            <div ref={ div => this.overlay = div } className='menu-select-overlay'></div>
          </div>
        </nav>
      </header>
    )
  }
}
