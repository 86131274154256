import React, { Component } from "react"
import Seo from "./seo"
import Header from "./header"
import Navigation from "./navigation"
import FooterBanner from "./footer-banner"
import Footer from "./footer"
import { navigationContext, menuContext } from '../utils/context-providers'
import "../stylesheets/all.scss";

class Layout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false,
    }
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }

  componentDidMount() {
    document.querySelector('html').classList.remove('no-scroll')
  }

  render() {
    return (
      <navigationContext.Provider value={this.props.namespace}>
        <div id='barba-wrapper' data-namespace={this.props.namespace}>
          <Seo {...this.props} />
          <div className='transition-panel-1'></div>
          <div className='transition-panel-2'></div>

          <Navigation open={this.state.menuOpen} />

          <div className='barba-container'>
            <menuContext.Provider value={{
              open: this.state.menuOpen,
              toggleOpen: () => { this.toggleMenu() }
            }}>
              <Header />
            </menuContext.Provider>
            {this.props.children}
            { this.props.noFooterBanner ? null : <FooterBanner /> }
            <Footer />
          </div>
        </div>
      </navigationContext.Provider>
    )
  }
}

export default Layout
