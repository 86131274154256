import React, { Component } from 'react'
import { Link } from 'gatsby'
import { navigationContext } from '../utils/context-providers'
import SocialNetworks from '@components/social-networks'

export default class Navigation extends Component {
  static contextType = navigationContext;

  getClassNames(link) {
    return `nav__list-item ${this.context === link ? 'nav__list-item--active' : '' }`
  }

  render () {
    return (
      <div className='main-navigation-wrapper hidden'>
        <div className='navigation-container'>
          <div className='nav__col-1'>
            <div className='title-label-1 title-label-1--alt nav__title'>
              <div className='title-label-1__count'>
                01
              </div>
              <div className='title-label-1__seperator title-label-1__seperator--alt'></div>
              <div className='title-label-1__label'>
                Navigation
              </div>
            </div>
            <ul className='nav__list'>
              <li className={this.getClassNames('home')}>
                <Link className='nav__link' to='/'>
                  Home
                </Link>
              </li>
              <li className={this.getClassNames('services')}>
                <Link className='nav__link' to='/services'>
                  Services
                </Link>
              </li>
              <li className={this.getClassNames('case_studies')}>
                <Link className='nav__link' to='/case-studies'>
                  Case Studies
                </Link>
              </li>
              <li className={this.getClassNames('about_us')}>
                <Link className='nav__link' to='/about-us'>
                  About Us
                </Link>
              </li>
              <li className={this.getClassNames('contact_us')}>
                <Link className='nav__link' to='/contact-us'>
                  Contact Us
                </Link>
              </li>
              <li className={this.getClassNames('careers')}>
                <Link className='nav__link' to='/careers'>
                  Careers
                </Link>
              </li>
              <li className={this.getClassNames('blog')}>
                <Link className='nav__link' to='/blog'>
                  Blog
                </Link>
              </li>
            </ul>
          </div>
          <div className='nav__col-2'>
            <SocialNetworks wrapperClassList='nav__social social-layout-1--alt' linkClassList='social-layout-1__icon--alt'>
              <div className='social-layout-1__seperator social-layout-1__seperator--alt'></div>
              <a className='social-layout-1__email social-layout-1__email--alt' href='mailto:hello@wyeworks.com'>
                hello@wyeworks.com
              </a>
            </SocialNetworks>
          </div>
        </div>
      </div>
    )
  }
}
