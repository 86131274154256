import React from 'react'

export default (props) => {
  const { wrapperClassList, linkClassList, children } = props;

  return (
    <div className={`social-layout-1 ${wrapperClassList}`}>
      <a className={`social-layout-1__icon ${linkClassList}`} href='https://www.facebook.com/wyeworks' target='_blank' rel='noopener noreferrer'>
        <div className='icon-Facebook-icon'></div>
      </a>
      <a className={`social-layout-1__icon ${linkClassList}`} href='https://twitter.com/wyeworks' target='_blank' rel='noopener noreferrer'>
        <div className='icon-Twitter-icon'></div>
      </a>
      <a className={`social-layout-1__icon ${linkClassList}`} href='https://github.com/wyeworks' target='_blank' rel='noopener noreferrer'>
        <div className='icon-Github-icon'></div>
      </a>
      <a className={`social-layout-1__icon ${linkClassList}`} href='https://www.instagram.com/wyeworks/' target='_blank' rel='noopener noreferrer'>
        <div className='icon-Instagram-icon'></div>
      </a>
      {children}
    </div>
  )
}
