import React from 'react'
import { Link } from 'gatsby'
import SocialNetworks from '@components/social-networks'

export default function footer() {
  return (
    <footer>
      <div className='footer__wrapper'>
        <div className='footer-nav'>
          <ul className='footer-nav__list'>
            <li className='footer-nav__list-item'>
              <Link className='footer-nav__link' to='/'>
                Home
              </Link>
            </li>
            <li className='footer-nav__list-item'>
              <Link className='footer-nav__link' to='/services'>
                services
              </Link>
            </li>
            <li className='footer-nav__list-item'>
              <Link className='footer-nav__link' to='/case-studies'>
                Case Studies
              </Link>
            </li>
            <li className='footer-nav__list-item'>
              <Link className='footer-nav__link' to='/about-us'>
                about us
              </Link>
            </li>
            <li className='footer-nav__list-item'>
              <Link className='footer-nav__link' to='/contact-us'>
                contact us
              </Link>
            </li>
            <li className='footer-nav__list-item'>
              <Link className='footer-nav__link' to='/careers'>
                careers
              </Link>
            </li>
            <li className='footer-nav__list-item'>
              <Link className='footer-nav__link' to='/blog'>
                blog
              </Link>
            </li>
          </ul>
        </div>
        <div className='footer__social'>
          <SocialNetworks wrapperClassList='social-layout-1--1' linkClassList='social-layout-1__icon--alt'>
            <div className='social-layout-1__seperator social-layout-1__seperator--alt'></div>
            <a className='social-layout-1__email social-layout-1__email--alt' href='mailto:hello@wyeworks.com' >
              hello@wyeworks.com
            </a>
          </SocialNetworks>
        </div>
      </div>
    </footer>
  )
}
