import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

function SEO(props) {
  const {
    description,
    lang,
    meta,
    keywords,
    title,
    thumbnail,
    author,
    location
  } = props

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const siteUrl = data.site.siteMetadata.siteUrl
        const metaDescription = description || data.site.siteMetadata.description
        const metaAuthor = author || data.site.siteMetadata.author.toLowerCase()
        const metaThumbnail = `${siteUrl}${thumbnail || data.site.siteMetadata.thumbnail}`
        const canonicalUrl = siteUrl + location.pathname

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`${data.site.siteMetadata.title} | %s`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaThumbnail,
              },
              {
                property: 'og:url',
                content: siteUrl
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:creator`,
                content: `@${metaAuthor}`,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:site`,
                content: `@wyeworks`
              },
              {
                name: `twitter:image`,
                content: metaThumbnail
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            <link rel='canonical' href={canonicalUrl}></link>
            <link href="/favicon.png" rel="icon"></link>
            <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.css" rel="stylesheet"></link>

            {`<!-- Start of Async Drift Code -->`}
            <script>
              {`
                "use strict";
                !(function() {
                  var t = (window.driftt = window.drift = window.driftt || []);
                  if (!t.init) {
                    if (t.invoked)
                      return void (
                        window.console &&
                        console.error &&
                        console.error("Drift snippet included twice.")
                      );
                    (t.invoked = !0),
                      (t.methods = [
                        "identify",
                        "config",
                        "track",
                        "reset",
                        "debug",
                        "show",
                        "ping",
                        "page",
                        "hide",
                        "off",
                        "on"
                      ]),
                      (t.factory = function(e) {
                        return function() {
                          var n = Array.prototype.slice.call(arguments);
                          return n.unshift(e), t.push(n), t;
                        };
                      }),
                      t.methods.forEach(function(e) {
                        t[e] = t.factory(e);
                      }),
                      (t.load = function(t) {
                        var e = 3e5,
                          n = Math.ceil(new Date() / e) * e,
                          o = document.createElement("script");
                        (o.type = "text/javascript"),
                          (o.async = !0),
                          (o.crossorigin = "anonymous"),
                          (o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js");
                        var i = document.getElementsByTagName("script")[0];
                        i.parentNode.insertBefore(o, i);
                      });
                  }
                })();
                drift.SNIPPET_VERSION = "0.3.1";
                drift.load("bm39ineaa2cm");
              `}
            </script>
            {`<!-- End of Async Drift Code -->`}
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        author
        thumbnail
      }
    }
  }
`
